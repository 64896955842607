<template>
  <v-card
    class="transparent"
    flat
    :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-4' : 'mx-auto my-4 mt-12 pa-8'"
    :max-width="$vuetify.breakpoint.smAndUp ? 600 : ''"
  >
    <div class="overline primary--text">
      {{ $t('Login for club members')}}
    </div>
    <form @submit.prevent>
      <v-text-field filled v-model.trim="loginForm.email" type="text" autocapitalize="none" placeholder="Email" name="username">
      </v-text-field>

      <v-text-field filled v-model.trim="loginForm.password" type="password" placeholder="Password">
      </v-text-field>

      <v-card-actions class="px-0" :class="$vuetify.breakpoint.xsOnly ? 'block' : ''">
        <v-btn :block="$vuetify.breakpoint.xsOnly" :to="`/forgot-password?email=${loginForm.email}`" class="mb-4">
          <!-- <v-icon class="mr-1">mdi-head-question</v-icon> -->
          <v-icon class="mr-1">mdi-brain-off</v-icon>
          {{ $t('Forgot PW')}}
        </v-btn>
        <v-spacer></v-spacer>
        <!-- TODO: use &next=? -->
        <v-btn :block="$vuetify.breakpoint.xsOnly" :disabled="loginForm.email.length > 0 && loginForm.password.length > 0" color="pink" :to="`/signup?email=${loginForm.email}${$route.query.view ? `&next=/resources&view=${$route.query.view}` : ''}${$route.query.user ? `&next=/colleagues&user=${$route.query.user}` : ''}`" class="mb-4">
          <v-icon class="mr-1">mdi-draw-pen</v-icon>
          {{ $t('Sign up') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :block="$vuetify.breakpoint.xsOnly" :disabled="loginForm.email.length == 0" type="submit" color="primary" @click="login()" class="mb-4">
          <v-icon class="mr-1">mdi-login-variant-variant</v-icon>
          {{ $t('Log in')}}
        </v-btn>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
  export default {
    name: 'Login',
    
    data () {
      return {
        error: '',
        loginForm: {
          email: '',
          password: ''
        }
      }
    },

    created() {
      this.loginForm.email = this.$route.query.email || '';
    },

    methods: {
      login() {
        this.$store.dispatch('login', {
          email: this.loginForm.email,
          password: this.loginForm.password
        }).then(() => {
          console.log('Successful login.');
        }).catch(error => {
          this.loginForm.password = '';
          let msg = error.message
          if(error.message.includes('INVALID_LOGIN_CREDENTIALS')) {
            // Yes includes because this error is a weird thing that returns a stringified version of an error somehow
            msg = this.$t('Invalid user or password.');
          }
          this.$toasted.global.error({msg:msg});
        });
      }
    }

  }
</script>


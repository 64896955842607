import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const dark = {
    background: '#000',
    surface: '#15202b',
    primary: '#3f51b5',
    secondary: '#03dac6',
    error: '#f44336',
    info: '#2196F3',
    success: '#4caf50',
    warning: '#fb8c00',
}

const light = {
    /* background: '#d0cdc6', */
    /* background: '#d1b572', */
    /* background: '#f2cb88', */
    /* background: '#bcb888', */
    background: '#ffffff',
    primary: '#22aeff',  // #ff5722
    secondary: '#c96464',  // #9c27b0
    accent: '#009688',
    error: '#ff5722',
    warning: '#ff9800',
    info: '#03a9f4',
    success: '#8bc34a'
}

export default new Vuetify({
    theme: {
        defaultTheme: 'light',  // https://blog.logrocket.com/building-dynamic-vuetify-themes/
        themes: {
          dark,
          light
        },
      },
});

<template>
  <v-card
    class="transparent"
    flat
    :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-4' : 'mx-auto my-4 mt-12 pa-8'"
    :max-width="$vuetify.breakpoint.smAndUp ? 600 : ''"
  >
    <div class="overline primary--text pl-0">
      {{ $t('Reset password') }}
    </div>
    <form @submit.prevent>
      <v-text-field filled v-model.trim="email" type="email" placeholder="Email" id="email2"></v-text-field>
    </form>

    <v-card-actions class="px-0 mt-4" :class="$vuetify.breakpoint.xsOnly ? 'block' : ''">
      <v-btn :to="'/login?email='+email" :block="$vuetify.breakpoint.xsOnly" class="mb-4">
        <v-icon class="mr-1">mdi-arrow-u-left-top</v-icon>
        {{ $t('Back') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn type="submit" color="primary" @click="requestResetPassword()" :block="$vuetify.breakpoint.xsOnly" class="mb-4">
        <v-icon class="mr-1">mdi-send</v-icon>
        {{ $t('Reset password') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { auth } from '@/firebase'

  export default {
    name: 'ForgotPassword',

    props: {
      auth: Boolean,
    },

    data () {
      return {
        email: '',
      }
    },
    created() {
      this.email = this.$route.query.email || '';
    },
    methods: {
      async requestResetPassword() {
        try {
          await auth.sendPasswordResetEmail(this.email)
          this.$toasted.global.success({msg:"Check your email inbox for a reset link."});
        } catch (err) {
          this.$toasted.global.error({msg:err.message});
        }
      }
    },
  }
</script>

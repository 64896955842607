<template>
  <div :class="containerClasses">
    <v-tooltip :disabled="!tooltip" :bottom="!position || position === 'bottom'" :right="position === 'right'" :left="position === 'left'" :top="position === 'top'"  :color="$vuetify.theme.dark ? '#303030' : '#efefef'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :tabindex="tabindexLocal ? tabindexLocal : null" dense :small="small" icon @click="$refs.fileInput.click()" :class="buttonClasses ? buttonClasses : 'pink darken-3'" v-bind="attrs" v-on="on">
          <v-icon v-if="progress === 0 && !resizing" :small="small" :class="iconClasses ? iconClasses : 'white--text'" v-bind="attrs" v-on="on">
            mdi-{{icon ? icon : 'upload' }}
          </v-icon>
          <v-progress-circular v-else :value="progress" :indeterminate="resizing">
            <div style="font-size:.85em; font-family:sans-serif; line-height:1em; letter-spacing:-1px">
              <v-icon v-if="resizing" class="heartBeat">mdi-fullscreen-exit</v-icon>
              <span v-else>{{progress > 9 ? progress : progress+'%'}}</span>
            </div></v-progress-circular>
        </v-btn>
        <div v-if="$slots.default" @click="$refs.fileInput.click()" class="inline-block pa-2 pr-4 pointer hover">
          <slot></slot>
        </div>
      </template>
      <span>{{tooltip}}</span>
    </v-tooltip>

    <v-dialog
      v-model="morePhotos"
      fullscreen
    >
      <v-container fluid class="black pa-8 fill-height">
        <v-row><!-- spacer --></v-row>
        <v-row>
          <v-col>
            <v-btn block class="pa-8 mb-8 secondary" @click="morePhotos=false">
              <v-icon class="mr-2 op-75" large>mdi-checkbox-marked-circle</v-icon>
              {{$t('Finish')}}
            </v-btn>
            <v-btn block class="pa-8 mt-8 primary" @click="$refs.fileInput.click()">
              <v-icon class="mr-2 op-75" large>mdi-camera-enhance</v-icon>
              {{$t('Add another photo')}}..
            </v-btn>
          </v-col>
        </v-row>
        <div class="absolute grey--text fill-width text-center left bottom" v-if="progress > 0 && progress < 100">
          <span class="sine20">
            <div v-if="progress > 66" class="pb-4">
              {{$t("Pro Tip: You don't have to wait for the upload here..")}}
            </div>
            <v-icon  class="op-50">mdi-cloud-upload</v-icon>
            {{ progress+1 }}%
            <span v-if="totalFiles > 1">- {{totalFilesUploaded.length}}/{{totalFiles}}</span>
          </span>
          <v-progress-linear class="mt-3" :value="progress" height="7" stream :buffer-value="progress+5"></v-progress-linear>
        </div>
      </v-container>
    </v-dialog>

    <input
      type="file"
      ref="fileInput"
      style="display: none"
      :multiple="multiple"
      @change="startUpload"
      :capture="camera ? 'environment' : ''"
      :accept="type ? type : 'image/*'"
    />
  </div>
</template>

<script>
import { storage } from '../firebase'
export default {
  props: ['returnContent', 'containerClasses', 'type', 'target', 'multiple', 'tabindex', 'maxImageSize', 'orderStart', 'camera', 'icon', 'iconClasses', 'buttonClasses', 'tooltip', 'position', 'small'],

  data() {
    return {
      imageData: null,
      progress: 0,
      totalBytes: 0,
      totalFiles: 0,
      totalFilesUploaded: [],
      resizing: false,
      tabindexLocal: 0,
      morePhotos: false,
    };
  },

  created() {
    this.maxImageSizeLocal = this.maxImageSize ? this.maxImageSize : 0;
    this.tabindexLocal = parseInt(this.tabindex);
  },

  methods: {
    async startUpload(event) {
      this.$emit('uploadStarted');
      this.progress = 1;
      this.maxImageSizeLocal = this.maxImageSizeLocal ? this.maxImageSizeLocal : 0;
      this.totalFiles = event.target.files.length;
      this.totalFilesUploaded = [];

      // Do a photo again if on mobile
      if(this.camera && this.multiple) {
        // SAD: does not work without direc tuser input
        // this.$refs.fileInput.click();
        this.morePhotos = true;
      }

      for (let i = 0; i < event.target.files.length; i++) {
        if(this.returnContent) {
            // this.$emit('uploaded', await this.readFile(event.target.files[i]))
            const fileContent = await this.readFile(event.target.files[i]);
            this.$emit('uploaded', fileContent);
            this.progress += (100/event.target.files.length)-1;
        } else {
          this.uploadData(event.target.files[i], event.target.files.length-i);
        }
      }
      if(this.returnContent) {
        this.progress = 0;
        this.$refs.fileInput.value='';
      }
    },

    async readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;
          try {
            // Try to parse as JSON
            const jsonData = JSON.parse(content);
            resolve(jsonData); // Resolve with JSON if parse is successful
          } catch {
            // If parsing fails, resolve with plain text
            resolve(content);
          }
        };
        reader.onerror = () => {
          reject(new Error(`Error reading file: ${file.name}`));
          this.$emit('error', `Error reading file: ${file.name}`)
        };
        reader.readAsText(file); // Read file as text
      });
    },
    
    async uploadData(fileData, order) {
      if(this.maxImageSizeLocal > 0) {
        this.resizing = true;  // display icon in GUI
        // Get image sizes and resize if necessary
        fileData = await this.processImage(fileData);
        this.resizing = false;  // display icon in GUI
      }

      // Create path with filename but with additional random ID to allow dulicate names of files
      let path = [this.target, `${this.$helpers.createUid()}-${fileData.name}`].join("/");
      
      // Save file to db, either original or from resized blob
      let currentStorageRef =  storage.ref(path).put(fileData.blob ? fileData.blob : fileData);
      // const storageRef = uploadBytes(path).put(fileData);
      currentStorageRef.on(`state_changed`, (snapshot) => {
          this.totalBytes = snapshot.totalBytes;
          // Increment progress for one file
          if(this.totalFiles === 1) {
            this.progress = parseInt((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          }
        },(error) => {
          console.log(error.message);
          this.$emit('error', error.message)
        },() => {
          if(this.totalFiles === 1) {
            this.progress = 100;
            this.$emit('finished');
          }
          currentStorageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.finalizeUploadOfFile(fileData, order, path, url);
          });
        }
      );
    },

    // eslint-disable-next-line no-unused-vars
    async processImage(fileData) {
      // Check if is type image with absolute sizes (not vector)
      let isPixelImage = fileData.type.startsWith('image/') && !fileData.type.startsWith('image/svg');
      if(isPixelImage) {
        fileData.imageType = "image";
        // render image behind the scenes and get new sizes if necessary
        let resizedImage = await this.resizeImage(fileData, this.maxImageSizeLocal)
        .then(function (resizedImage) {
          return resizedImage
        }).catch(function (err) {
          console.error(err);
        });
        fileData.width = resizedImage.width;
        fileData.height = resizedImage.height;
        if(resizedImage.blob) fileData.blob = resizedImage.blob;
        return fileData;

      } else if(fileData.type.startsWith('image/')) {
        // Was vector/svg/other img type?..
        fileData.imageType = fileData.type;
        return fileData;

      } else {
        // Was data
        return fileData;
      }
    },

    // Siiiiide effects
    finalizeUploadOfFile(fileData, order, path, url) {
      this.$emit('uploaded', {
        name: fileData.name,
        path: path,
        url: url,
        uploaded: new Date(),
        lastModified: fileData.lastModified,
        fileSize: fileData.blob ? fileData.blob.size : fileData.size,
        humanSize: this.$helpers.humanSize(fileData.blob ? fileData.blob.size : fileData.size),
        type: fileData.type,
        ...(fileData.imageType && {
            image: {
              imageType: fileData.imageType,
              width: fileData.width,
              height: fileData.height,
              aspect: fileData.width / fileData.height,
            }
          }),
        order: (this.orderStart ? this.orderStart : 0) + order - this.totalFilesUploaded.length - 1,
        comment: '',
      })
      if(this.totalFiles === 1) {
        // Reset progress for one file
        this.progress = 0;
      } else {
        // Increment progress now for multiple files
        this.totalFilesUploaded.push(url)
        this.progress = parseInt((this.totalFilesUploaded.length / this.totalFiles) * 100)
        // Reset progress now for multiple files
        if(this.totalFilesUploaded.length == this.totalFiles) {
          this.progress = 0;
          this.$emit('finished');
        }
      }
      this.totalBytes = 0;
      this.$refs.fileInput.value='';  // reset form for later use of the same file
    },

    /* COPY PASTA */
    /* https://stackoverflow.com/a/39235724 */
    resizeImage(file, maxSize) {
      var reader = new FileReader();
      var image = new Image();
      var canvas = document.createElement('canvas');
      var dataURItoBlob = function (dataURI) {
          var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
              atob(dataURI.split(',')[1]) :
              unescape(dataURI.split(',')[1]);
          var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
          var max = bytes.length;
          var ia = new Uint8Array(max);
          for (var i = 0; i < max; i++)
              ia[i] = bytes.charCodeAt(i);
          return new Blob([ia], { type: mime });
      };

      var resize = function() {
          var width = image.width;
          var height = image.height;
          if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
          } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
              }
          }

          // Don't do canvas rendering if image was small enough
          if(width != image.width) {
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL('image/jpeg');
            canvas.remove();

            return {
              blob: dataURItoBlob(dataUrl),
              width: parseInt(width),
              height: parseInt(height),
            };
          } else {
            return {
              width: image.width,
              height: image.height,
            }
          }
      };

      return new Promise(function (ok, no) {
          if (!file.type.match(/image.*/)) {
              no(new Error("Not an image"));
              return;
          }
          reader.onload = function (readerEvent) {
              image.onload = function () { 
                return ok(resize());
              };
              image.src = readerEvent.target.result;
          };
          reader.readAsDataURL(file);
      });
    },
  },
};
</script>

<style scoped>
  .v-tooltip__content.menuable__content__active {
      opacity: 1 !important;
  }
</style>

import { render, staticRenderFns } from "./Copy.vue?vue&type=template&id=1ac33afc&scoped=true"
import script from "./Copy.vue?vue&type=script&lang=js"
export * from "./Copy.vue?vue&type=script&lang=js"
import style0 from "./Copy.vue?vue&type=style&index=0&id=1ac33afc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ac33afc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})

<template>
  <div class="pb-16 mb-16">
    <div class="overline primary--text">{{ $t('How renting works')}}</div>
    <!-- <v-row>
      <v-col class="pl-2 pt-2" cols="12">
        col!
      </v-col>
    </v-row> -->

    <v-card
      class="transparent user-select"
      :class="$vuetify.breakpoint.xs ? 'fill-height ma-0 pa-4' : $vuetify.breakpoint.mdAndUp ? 'mx-auto my-4 mt-12 pa-8' : 'mx-auto my-4 mt-0 pa-8'"
      :max-width="$vuetify.breakpoint.smAndUp ? 666 : 6666"
      flat
    >
      <v-card-title class="justify-center primary--text" style="word-break: initial;">
        <!-- <img class="op-12" :class="$vuetify.theme.dark ? 'invert' : 'darken-3'" src="logo.svg" :style="$vuetify.breakpoint.smAndUp ? 'height:4em' : 'height:2em'"> -->
        <h1>{{$t('Dear renter')}}</h1>
      </v-card-title>
      <v-card-text class="italics text-center">
        <Loader :hideLoading="true" :interval="1111"></Loader>
      </v-card-text>
      <v-card-subtitle class="text--center">
        {{$t('Here are some brief instructions on how you can borrow things from us.')}}
      </v-card-subtitle>
    </v-card>

    <v-card
      class="transparent user-select"
      :class="$vuetify.breakpoint.xs ? 'fill-height ma-0 pa-4' : $vuetify.breakpoint.mdAndUp ? 'mx-auto my-4 mt-12 pa-8' : 'mx-auto my-4 mt-0 pa-8'"
      :max-width="$vuetify.breakpoint.smAndUp ? 666 : 6666"
      flat
    >
      <v-card-title class="justify-center primary--text mt-12" style="word-break: initial;">
        <v-icon large color="secondary">mdi-cash-100</v-icon>
      </v-card-title>
      <h2 class="primary--text">
        {{ $t('Prices')}}
      </h2>
      <div v-if="$i18n.locale === 'de'">
        <p>
          Die Mietpreise für die Gegenstände werden nicht pro Tag sondern pro Projekt berechnet. Für Studierende, welche die Gegenstände weniger als 30 Tage ausleihen, kannst du um einen Rabatt bitten.
        </p>
        <p>
          <span class="bolder">Pro Besuch im Fundus berechnen wir 75 CHF</span> (Ausleihe, Rückgabe, Visionierung).<br>
          Wir gleichen uns mit dem Fundus in Muri ab, damit wir in etwa dieselben Preise haben.
        </p>
        <p>
          Mit dem Abholen der Gegenstände bestätigst du, mit unseren AGBs einverstanden zu sein.
          Du findest die AGBs <router-link to="/agb">hier</router-link>.<br>
        </p>
      </div>
      <div v-else>
        Translation is missing!
      </div>

      <v-card-title class="justify-center primary--text mt-12" style="word-break: initial;">
        <v-icon large color="secondary">mdi-forklift</v-icon>
        <v-icon color="secondary" class="nudge-y-12">mdi-package-variant</v-icon>
        <v-icon color="secondary" class="nudge-y-12 mr-4">mdi-package-variant-closed</v-icon>
        <v-icon large color="secondary">mdi-truck-cargo-container</v-icon>
      </v-card-title>
      <h2 class="primary--text mt-8">
        {{ $t('Renting')}}
      </h2>
      <ol class="niceList" v-if="$i18n.locale === 'de'">
        <li>
          Du machst mit uns einen Termin ab, an dem du vorbeikommen kannst (<span class="bolder">Wir sind nicht immer dort!</span>).
        </li>
        <li>
          Wir treffen uns im Fundus Ça Tourne an der
          <a href="https://maps.app.goo.gl/WMG1meqv8GLMXoyn7" target="_blank">
            <v-icon small color="primary">mdi-map-marker</v-icon>
            <span class="bolder">Eichstrasse 44, 8152 Opfikon</span>
          </a>
          und du fotografierst die Dinge, die du ausleihen möchtest, vor Ort (Sehr wichtig!) und packst sie
          selbst ein. Bringe deine Kisten zum Einpacken bitte selbst mit. Wir haben eine Laderampe.
        </li>
        <li>
          Du schickst uns die <span class="bolder">Fotos</span> der ausgeliehenen Gegenstände innert 5
          Tage zu (gerne ein .pdf mit jeweils 4 Fotos pro seite) und notierst die <span class="bolder">Adresse
          der Produktionsfirma</span>, den <span class="bolder">Namen des Projekts</span> und den <span class="bolder">Mietzeitraum</span>.
          Weiter unten siehst du, wie die Fotos und die Datei aussehen sollte. Anhand von dem schicken
          wir dir nach der Rückgabe eine Rechnung.
        </li>
        <li>
          Solltest du ein Möbelstück oder Grossrequisit reservieren wollen, findest du im Fundus vorgedruckte
          Zettel, die du ausfüllen und auf das Möbel kleben darfst.
        </li>
      </ol>
      <div v-else>
        Translation is missing!
      </div>

      <v-card-title class="justify-center primary--text mt-12" style="word-break: initial;">
        <v-icon large color="secondary" class="mirr-x">mdi-truck-cargo-container</v-icon>
        <v-icon color="secondary" class="rot-90">mdi-smoke</v-icon>
      </v-card-title>
      <h2 class="primary--text mt-8">
        {{ $t('Returning')}}
      </h2>
      <ol class="niceList" v-if="$i18n.locale === 'de'">
        <li>Du machst mit uns einen Termin für die Rückgabe ab (<span class="bolder">Wir sind nicht immer dort!</span>).</li>
        <li>Wir treffen uns im Fundus Ça Tourne und du darfst deine Gegenstände wieder auspacken und in die Gestelle zurückräumen. Wenn du bei einem Gegenstand unsicher bist, frag uns bitte, wohin er gehört.</li>
        <li>Kaputte Gegenstände gehören nicht zurück ins Gestell. Gib uns bitte Bescheid, damit wir sie reparieren können.</li>
        <li>Fehlt einer der Gegenstände, die du ausgeliehen hast, gib uns bitte ebenfalls Bescheid und wir lassen dich wissen, ob du ihn für uns ersetzen musst oder ob wir dir dafür eine Rechnung stellen. </li>
        <li>Wir senden dir eine Rechnung zu, die sich zusammenstellt aus:
            <ul class="niceList">
              <li>Miete der Gegenstände</li>
              <li>Pauschale für Besuche (75 CHF pro Besuch)</li>
              <li>Allfällige Kosten für Verbrauchsmaterial </li>
              <li>Allfällige Kosten für Reparaturen/das Ersetzen von Dingen</li>
            </ul>
        </li>
      </ol>
      <div v-else>
        Translation is missing!
      </div>

      <p class="mt-8">
        {{ $t('Any questions? Send us an email:')}}
        <a class="decorated"  href="mailto:fundus@catourne.ch">
          <v-icon small color="primary">mdi-email</v-icon>
          fundus@catourne.ch
        </a>
      </p>
      <p>{{ $t('We look forward to seeing you')}} =)</p>
      <p>{{ $t('Your Ça Tourne Team')}}</p>

      <hr class="mt-12 op-25">
      <div class="overline primary--text">{{ $t('Downloads')}}</div>
      <v-list color="transparent">
        <a href="./Fundus_How_To.pdf" target="_blank" class="no-underline">
          <v-list-item class="hoverList">
            <v-icon small color="primary" class="mr-2">mdi-file-pdf-box</v-icon>
            {{  $t('How to rent factsheet') }}.pdf
          </v-list-item>
        </a>
        <a href="./Fundus_How_To_foto.pdf" target="_blank" class="no-underline">
          <v-list-item class="hoverList">
            <v-icon small color="primary" class="mr-2">mdi-file-pdf-box</v-icon>
            {{  $t('How to foto') }}.pdf
          </v-list-item>
        </a>
      </v-list>
    </v-card>
  </div>
</template>

<script>
// import Copy from '@/components/Copy'
import Loader from "../components/Loader.vue"

  export default {
    name: 'Renting',
    props: {
      auth: Boolean,
      user: Object,
    },
    components: {
      // Copy,
      Loader,
    },
    
    data () {
      return {
      }
    },
  }
</script>

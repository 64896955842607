<template>
  <!-- IMAGE PREVIEW -->
  <div v-if="localImages && localImages.length" :class="containerClasses">
    <!-- PREVIEW -->
    <div v-if="!windowed">
      <v-img
        class="fill-height gallery preview"
        :ref="'gallery-preview-'+propId"
        @wheel="zoom($event, `props/${propId}/${subfolder}/${magnifyDialogImg.filename}`)"
        @mouseleave="resetZoom()"
        :class="[$vuetify.theme.dark ? 'darken-4' : 'lighten-3', rounded ? 'rounded' : '', imageClasses ? imageClasses : 'grey']"
        :aspect-ratio="aspectRatio ? aspectRatio : '1.777'"
        :max-height="$vuetify.breakpoint.smOnly ? '50vw' : $vuetify.breakpoint.mdAndUp ? '50vh' : '100vw'"
        :lazy-src="`props/${propId}/${subfolder}/${$helpers.imageChooser(magnifyDialogImg.alternative_sizes_filenames, 'small')}`"
        :src="`props/${propId}/${subfolder}/${$helpers.imageChooser(magnifyDialogImg.alternative_sizes_filenames, 'large')}`"
        contain
      >
        <!-- @error="waitForThumbnails([magnifyDialog], 0)" -->
        <div v-if="localImages.length > 1"
          v-touch="{
            left: () => magnifyNext(),
            right: () => magnifyPrev(),
          }"
          @click.stop="magnifyPrev()"
          class="pointerLeft absolute left fill-height"
          :style="$vuetify.breakpoint.xs ? 'width:50%' : 'width:33%'"
        >
        </div>
        <div v-if="localImages.length > 1"
          v-touch="{
            left: () => magnifyNext(),
            right: () => magnifyPrev(),
          }"
          @click.stop="magnifyNext()"
          class="pointerRight absolute right fill-height"
          :style="$vuetify.breakpoint.smAndDown ? 'width:50%' : 'width:33%'"
        >
        </div>
      </v-img>
      <v-card-actions class="pt-0 pb-2 caption">
        <span class="capitalize">
          {{$helpers.truncate(magnifyDialogImg.data.name_without_extension, 25, '…')}}
        </span>
        <v-spacer></v-spacer>
        <span class="grey--text">
          {{ magnifyIndex+1 }}/{{ localImages.length }} -
          {{ $moment(magnifyDialogImg.data.modified).format("DD.MM.YYYY") }}
        </span>
        <v-spacer></v-spacer>
        <a
          :href="`${mainDomain}/props/${propId}/${subfolder}/${magnifyDialogImg.filename}`"
          target="_blank"
          class="no-underline"
        >
          <v-btn icon :small="$vuetify.breakpoint.smAndUp">
            <Info 
              :text="$t('Open this in max resolution')"
              icon="arrow-top-right-bold-box"
              :small="$vuetify.breakpoint.smAndUp"
              opacity="1"
              iconClasses="primary--text"
            ></Info>
          </v-btn>
        </a>
        <Share
          position="bottom"
          buttonColor="white"
          icon="image-share"
          :small="$vuetify.breakpoint.smAndUp"
          iconClasses="primary--text"
          :text="`${mainDomain}/props/${propId}/${subfolder}/${magnifyDialogImg.alternative_sizes_filenames[magnifyDialogImg.alternative_sizes_filenames.length-1]}`"
          :tooltip="$t('Share this image')"
          opacity="1"
        ></Share>
      </v-card-actions>
    </div>

    <!-- THUMBNAILS -->
    <div v-if="showThumbnails" :class="[$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-3', thumbnailContainerClasses]">
      <v-card 
        v-for="(img, x) in localImages"
        :key="x"
        class="inlineBlock mr-1"
        :tile="!rounded"
        :class="thumbnailClasses ? thumbnailClasses : 'pr-3 mr-2 mb-2'"
      >
        <!-- $vuetify.breakpoint.smAndDown ? 'grey darken-4' : '',   -->
        <v-img
          class="pa-0 ma-o gallery thumbnails"
          :class="[!windowed && magnifyIndex == x ? 'active' : '', rounded ? 'rounded' : '', imageClasses ? imageClasses : 'grey darken-4 ']"
          elevation-8
          style="vertical-align:top; transition: background-color .4s ease-in-out;"
          :lazy-src="`props/${propId}/${subfolder}/${$helpers.imageChooser(img.alternative_sizes_filenames, 'small')}`"
          :src="`props/${propId}/${subfolder}/${$helpers.imageChooser(img.alternative_sizes_filenames, 'medium')}`"
          :contain="!contain && !cover ? true : contain"
          :cover="cover"
          :width="width ? width : '50%'"
          :height="height ? height : '50vw'"
          @click="magnify(img, x)"
        ></v-img>
        <v-card-text v-if="showNameThumbnail" class="small pa-1 text--center">
          {{$helpers.truncate(img.data.name_without_extension, 25, '…')}}
        </v-card-text>
      </v-card>
    </div>

    <!-- MAGNIFY  -->
    <v-dialog
      v-model="magnifyDialog"
      v-if="magnifyDialog && windowed"
      :max-width="$vuetify.breakpoint.sm
        ? '85%'
        : magnifyDialogImg.data && magnifyDialogImg.data.width > magnifyDialogImg.data.height ? '85%': '45%'
      "
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :ripple="false"
      scrollable
    >
      <v-card :ripple="false">
        <v-card-text class="pa-0" style="max-height:90%;" :class="$vuetify.breakpoint.smAndDown ? 'relative' : ''">
          <v-img
            class="pointerClose fill-height"
            :class="[$vuetify.breakpoint.smAndDown ? '' : 'relative', imageClasses ? imageClasses : 'grey darken-3']"
            :lazy-src="`props/${propId}/${subfolder}/${$helpers.imageChooser(magnifyDialogImg.alternative_sizes_filenames, 'small')}`"
            :src="`props/${propId}/${subfolder}/${$helpers.imageChooser(magnifyDialogImg.alternative_sizes_filenames, 'large')}`"
            @click="magnifyDialog = false"
            contain
          >
            <!-- @error="waitForThumbnails([magnifyDialog], 0)" -->
            <div v-if="localImages.length > 1"
              v-touch="{
                left: () => magnifyNext(),
                right: () => magnifyPrev(),
              }"
              @click.stop="magnifyPrev()"
              class="pointerLeft absolute left fill-height"
              :style="$vuetify.breakpoint.xs ? 'width:50%' : 'width:33%'"
            >
            </div>
            <div v-if="localImages.length > 1"
              v-touch="{
                left: () => magnifyNext(),
                right: () => magnifyPrev(),
              }"
              @click.stop="magnifyNext()"
              class="pointerRight absolute right fill-height"
              :style="$vuetify.breakpoint.smAndDown ? 'width:50%' : 'width:33%'"
            >
              <div v-if="$vuetify.breakpoint.smAndDown" class="text-right fixed top right mt-8">
                <v-btn small color="red" fab absolute top right class="" @click="magnifyDialog = false" >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </v-img>
        </v-card-text>
      <v-card-actions class="px-2 py-1 caption">
        <span class="capitalize">
          {{$helpers.truncate(magnifyDialogImg.data.name_without_extension, 25, '…')}}
        </span>
        <v-spacer></v-spacer>
        <span class="grey--text">
          {{ magnifyIndex+1 }}/{{ localImages.length }} -
          {{ $moment(magnifyDialogImg.data.modified).format("DD.MM.YYYY") }}
        </span>
        <v-spacer></v-spacer>
        <a
          :href="`${mainDomain}/props/${propId}/${subfolder}/${magnifyDialogImg.filename}`"
          target="_blank"
        >
          <v-btn icon small>
            <Info 
              :text="$t('Open this in max resolution')"
              icon="arrow-top-right-bold-box"
              opacity="1"
              iconClasses="primary--text"
            ></Info>
          </v-btn>
        </a>
        <Share
          position="bottom"
          buttonColor="white"
          icon="image-share"
          small
          iconClasses="primary--text"
          :text="`${mainDomain}/props/${propId}/${subfolder}/${magnifyDialogImg.alternative_sizes_filenames[magnifyDialogImg.alternative_sizes_filenames.length-1]}`"
          :tooltip="$t('Share this image')"
          opacity="1"
        ></Share>
        <v-btn v-if="$vuetify.breakpoint.mdAndUp" @click="magnifyDialog = false">
          <v-icon class="mr-2">mdi-close</v-icon>
          {{ $t("Close") }}
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Info from '@/components/Info'
import Share from '@/components/Share'

  export default {
    props: {
      auth: Boolean,
      user: Object,
      settings: Object,
      images: Array,      // Full-scale array of objects with all metadata
      imagePaths: Array,  // only list of paths to images
      subfolder: String,
      width: String,
      height: String,
      propId: Number,
      containerClasses: String,
      thumbnailClasses: String,
      imageClasses: String,
      thumbnailContainerClasses: String,
      rounded: Boolean,
      contain: Boolean,
      showThumbnails: Boolean,
      showNameThumbnail: Boolean,
      cover: Boolean,
      windowed: Boolean,
      aspectRatio: String,
    },
    components: {
      Share,
      Info,
    },
    data() {
      return {
        localImages: [],
        magnifyIndex: 0,
        zoomLevel: 100,
        magnifyDialog: false,
        magnifyDialogImg: {},
      }
    },
    watch: {
      // eslint-disable-next-line no-unused-vars
      images: {
        handler() {
          // Re-sort and mangle images whenever `images` changes
          this.initGallery();
        },
        immediate: true, 
      },
      // eslint-disable-next-line no-unused-vars
      imagePaths: {
        handler() {
          // Re-sort and mangle images whenever `images` changes
          this.initGallery();
        },
        immediate: true, 
      },
    },
    computed: {
      mainDomain() {
        return new URL(window.location.origin).origin;
      }
    },
    mounted() {
      // Add keydown listener
      window.addEventListener("keydown", this.handleKeydown);
    },
    created() {
      this.initGallery();
    },
    beforeDestroy() {
      // Remove keydown listener
      window.removeEventListener("keydown", this.handleKeydown);
    },
    methods: {
      handleKeydown(event) {
        const sourceTagName = event.target.tagName.toLowerCase();
        if (
          ["input", "textarea", "select"].includes(sourceTagName) ||        // Ignore keystrokes from input fields, textareas etc
          event.target.isContentEditable ||                                 // and editable elements
          event.shiftKey || event.ctrlKey || event.altKey || event.metaKey  // Ignore events with any modifier keys
        ) {
          return;
        }

        // console.log(event.key)
        if (event.key === "ArrowLeft") {
          this.magnifyPrev();
          event.preventDefault();
        } else if (event.key === "Escape") {
          this.resetZoom();
        } else if (event.key === "ArrowRight" || event.key === " ") {
          this.magnifyNext();
          event.preventDefault();
        }
      },
      zoom(event, maxResolutionFilePath) {
        event.preventDefault();
        // console.log("Scroll detected!");
        let target = this.$refs['gallery-preview-'+this.propId].$el.querySelector('.v-image__image');

        let maxZoom = 600;  // Percent
        let minZoom = 50;   // Percent

        // Adjust zoom level based on scroll direction
        if (event.deltaY < 0) {
          // Limit max zoom in to minZoom% - css property "bg-size: contain;" is not == 100%
          this.zoomLevel = Math.max(this.zoomLevel - 3, minZoom);
        } else if (event.deltaY > 0) {
          // Limit min zoom out to maxZoom%
          this.zoomLevel = Math.min(this.zoomLevel + 3, maxZoom);
        }
        // console.log(this.zoomLevel);
        target.style.backgroundSize = `${this.zoomLevel}%`;

        // Swap max resolution file if zoomed in enough
        if(this.zoomLevel > maxZoom/2 && target.style.backgroundImage !== `url("${maxResolutionFilePath}")`) {
          target.style.backgroundImage = `url("${maxResolutionFilePath}")`;
        }

        // Adjust background position for zoom focus if not zoomed in max
        if(this.zoomLevel < maxZoom) {
          const containerRect = target.getBoundingClientRect();
          const mouseX = (event.clientX - containerRect.left) / containerRect.width;
          const mouseY = (event.clientY - containerRect.top) / containerRect.height;
          //console.log(`${mouseX * 100}% ${mouseY * 100}%`)
          target.style.backgroundPosition = `${mouseX * 100}% ${mouseY * 100}%`;
          // FIXME: Adjust for zoom level: center the zoom around the cursor
          // const adjustedX = (mouseX * 100 * (100/(this.zoomLevel-100))); // Mouse relative to zoom level
          // const adjustedY = (mouseY * 100 * (100/(this.zoomLevel-100))); // Mouse relative to zoom level
          // target.style.backgroundPosition = `${adjustedX}% ${adjustedY}%`;
        }
      },
      resetZoom() {
        this.zoomLevel = 100;
        let galleryPreview = this.$refs['gallery-preview-'+this.propId];
        if(galleryPreview) {
          let target = galleryPreview.$el.querySelector('.v-image__image');
          target.style.backgroundSize=`contain`;
          target.style.backgroundPosition=`center center`;
        }
      },
      initGallery() {
        if(this.images) {
          this.localImages = this.images;
        } else {
          // console.log(this.imagePaths)
          this.localImages = this.transformImagePathsToObjects(this.imagePaths);
          // console.log(this.localImages)
        }
        this.sortPosterImage();
        if(!this.windowed) {
          this.magnify(this.localImages[0], 0)
        }
      },
      sortPosterImage() {
        // Sort poster image first
        this.localImages = this.localImages.slice().sort((a, b) => {
          return b.is_posterimage - a.is_posterimage;
        });
      },
      magnify(imgData, index) {
        this.resetZoom();
        this.magnifyDialog = true;
        this.magnifyIndex = index;
        this.magnifyDialogImg = imgData;
      },
      magnifyPrev() {
        this.resetZoom();
        this.magnifyIndex = this.magnifyIndex-1;
        this.magnifyIndex = this.magnifyIndex < 0
          ? this.localImages.length-1
          : this.magnifyIndex;
        this.magnifyDialogImg = this.localImages[this.magnifyIndex];
      },

      magnifyNext() {
        this.resetZoom();
        this.magnifyIndex = this.magnifyIndex+1;
        this.magnifyIndex = this.magnifyIndex > this.localImages.length-1
          ? 0
          : this.magnifyIndex;
        this.magnifyDialogImg = this.localImages[this.magnifyIndex];
      },
      transformImagePathsToObjects(paths) {
        return paths.map((path) => {
          const filename = path.split('/').pop(); // Extract filename from path
          const nameWithoutExtension = filename.split('.').slice(0, -1).join('.');
          // const extension = filename.split('.').pop(); // Extract file extension
          const modifiedDate = new Date().toISOString(); // Example modification date

          return {
            filename: filename,
            alternative_sizes_filenames: [
              filename
              // `${nameWithoutExtension}_small.jpg`,
              // `${nameWithoutExtension}_medium.jpg`,
              // `${nameWithoutExtension}_large.jpg`
            ],
            data: {
              name_without_extension: nameWithoutExtension,
              modified: modifiedDate,
              // width: 100, // Placeholder width
              // height: 100 // Placeholder height
            }
          };
        });
      },
    },
  };
</script>

<style>
.v-image.gallery.preview .v-image__image {
  transition: background-position 250ms ease-in-out;
}
.v-image.gallery.thumbnails .v-image__image {
  box-shadow: inset 0 0 rgba(255,255,255,.85), inset -0 -0 rgba(255,255,255,.85);
  transition: box-shadow 120ms ease-out;
}
.v-image.gallery.thumbnails.active .v-image__image {
  box-shadow: inset .25em .25em rgba(255,255,255,.85), inset -.25em -.25em rgba(255,255,255,.85);
}
</style>

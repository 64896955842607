<template>
    <!-- MOBILE HAMBURGER MENU -->
    <v-navigation-drawer
      v-model="menuLocal"
      left
      :fixed="$vuetify.breakpoint.mdAndDown"
      :permanent="$vuetify.breakpoint.lgAndUp"
      :width="$vuetify.breakpoint.xs ? '100%'
        : $vuetify.breakpoint.sm ? '75%'
        : $vuetify.breakpoint.md ? '27%' : '210px'"
    >
      <v-list-item :class="$vuetify.breakpoint.smAndDown ? '' : 'mt-5'">
        <v-list-item-content>
          <v-img v-if="$vuetify.breakpoint.mdAndDown" class="op-50 mt-2" :style="$vuetify.breakpoint.smAndDown ? 'height:2.5em' : $vuetify.breakpoint.mdAndDown ? 'height:1.75em' : 'height:3em'" contain :class="$vuetify.theme.dark ? 'invert' : ''" src="logo.svg"></v-img>
          <img v-if="$vuetify.breakpoint.sm" class="mt-4" :class="$vuetify.theme.dark ? 'invert' : 'darken-3'" src="stellarDb.svg" style="height:1.5em"
            title="Stellar [/ˈstɛlə/]: 1. Of, pertaining to, or characteristic of stars. 2. Heavenly 3. (by extension) Exceptional, exceptionally good."
          >
          <v-btn v-if="$vuetify.breakpoint.mdAndDown" class="ma-4 absolute top right" :class="$vuetify.breakpoint.mdOnly ? 'mt-3' : ''" large @click="$emit('menu');" icon>
            <v-icon color="secondary" :large="!$vuetify.breakpoint.mdOnly" class="mx-1">mdi-close</v-icon>
          </v-btn>
        </v-list-item-content>
      </v-list-item>

      <v-list dense>
        <template 
            v-for="(link, index) in compiledLinks"
        >
          <v-list-item
            v-if="link.to"
            :key="`${link.title}-${index}`"
            :to="link.to"
            @click.native="$emit('menu');"
            link
          >
            <v-list-item-avatar :class="$vuetify.breakpoint.lgAndUp ? 'my-0 mr-0' : ''" :style="$vuetify.breakpoint.lgAndUp ? 'height:initial;' : ''" >
              <v-icon color="primary" class="mx-0">mdi-{{ link.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="pa-0">
              <template v-if="$vuetify.breakpoint.mdAndDown && link.tooltip.length">
                <v-list-item-title>{{ $t(link.title) }}</v-list-item-title>
                <v-list-item-subtitle v-if="$vuetify.breakpoint.mdAndDown && link.tooltip.length">
                  {{ $t(link.tooltip) }}
                </v-list-item-subtitle>
              </template>
              <template v-else-if="link.tooltip.length">
                <v-tooltip :color="$vuetify.theme.dark ? '#303030' : '#efefef'" right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on" class="pa-2 fill-width">{{ $t(link.title) }}</v-list-item-title>
                  </template>
                  <div style="max-width:275px; min-width:125px" :class="$vuetify.theme.dark ? '' : 'black--text'">
                    {{ $t(link.tooltip) }}
                  </div>
                </v-tooltip>
              </template>
              
            </v-list-item-content>
          </v-list-item>

          <v-list-item-subtitle
            v-else
            :key="`${link.title}-${index}-${$helpers.createUid()}`"
            class="small grey--text mt-4 pl-4"
            dense
          >
            {{ $t(link.title) }}
          </v-list-item-subtitle>
        </template>

        <v-divider v-if="auth"></v-divider>

        <v-list-item v-if="$vuetify.breakpoint.xs">
          <LanguageSwitcher :auth="auth" :user="user" :settings="settings"></LanguageSwitcher>
          <BrightnessSwitcher :auth="auth" :user="user" :settings="settings"></BrightnessSwitcher>
        </v-list-item>

        <v-list-item v-if="auth" @click="logout()" link>
          <v-list-item-avatar :class="$vuetify.breakpoint.lgAndUp ? 'mr-2' : ''">
            <v-icon color="secondary" class="mx-0">mdi-logout-variant-variant</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- FOOTER -->
      <template v-slot:append>
        <div class="pa-2 caption">
          <span class="overline pr-1" style="line-height:.5em">Stellar&middot;DB
          <span style="text-transform:lowercase;">v</span>{{appVersion}}</span>
          <br>
          <span class="grey--text">{{ $t('Published') }}: <span class="titleCase">{{$helpers.timeRelativeToNow($moment(metadata.published))}}</span></span>
          <span v-if="$vuetify.breakpoint.smOnly" class="mx-2">&middot;</span>
          <br v-else>
          <span class="grey--text">{{ $t('Last update from DB') }}: {{$helpers.timeRelativeToNow($moment(metadata.date_of_collection))}}</span>
          <span v-if="$vuetify.breakpoint.smOnly" class="mx-2">&middot;</span>
          <br v-else>
          <span class="pr-1" v-if="$vuetify.breakpoint.smAndUp">Made with <v-icon small color="rgba(255,0,255,.5)">mdi-heart</v-icon> by</span>
          <a href="https://www.telefabi.ch" target="_blank" class="no-underline"><span class=" pink--text ">telefabi.ch</span></a>
        </div>
      </template>
    </v-navigation-drawer>
</template>

<script>
import {version} from '../../package'
import LanguageSwitcher from "./LanguageSwitcher.vue"
import BrightnessSwitcher from "./BrightnessSwitcher.vue"
import { mapState } from 'vuex'

export default {
  props: {
    auth: Boolean,
    user: Object,
    settings: Object,
    menu: Boolean,
  },
  components: {
    LanguageSwitcher,
    BrightnessSwitcher,
  },
  data() {
    return {
      on: false,
      attrs: false,
      appVersion: version,
      links: [
        // ALLE
        // {title: 'General', auth: true},
        {to: '/db/', auth: "both", role: 'none', title: 'Fundus', icon: 'briefcase', tooltip: 'See all our props'},
        {to: '/favorites', auth: "both", role: 'none', title: 'Favorites', icon: 'star', tooltip: 'Props for later'},
        {to: '/renting', auth: "both", role: 'user', title: 'Renting', icon: 'package-variant', tooltip: 'How renting works'},
        {to: '/about', auth: "both", role: 'user', title: 'About Ça Tourne', icon: 'information', tooltip: 'About the club'},
        
        // NUR INTERNA
        // {title: 'Admin', auth: true, role: 'admin'},
        {to: '/users', auth: true, role: 'supervisor', title: 'User management', icon: 'account-group', tooltip: 'All internal + external users'},
        {to: '/admin', auth: true, role: 'admin', title: 'Admin', icon: 'server', tooltip: 'Backups, Maintenance mode etc'},
        
        // ALLE
        // {title: 'Session & User', auth: true},
        {to: '/profile', auth: true, role: 'user', title: 'Your profile', icon: 'account-circle', tooltip: 'Email, password, avatar'},
        {to: '/login', auth: false, role: 'none', title: 'Login club members', icon: 'login-variant-variant', tooltip: 'Only for club members'},
      ],
    }
  },
  watch: {
  },

  async created() {
  },

  computed: {
    ...mapState(['metadata']),
    compiledLinks: {
      get () {
        let localLinks = [];
        let roles = [];
        if(this.user.role) roles = this.$helpers.findElementInNewArray(
          this.user.role,
          ['user', 'supervisor', 'admin'],
          [['user'], ['user', 'supervisor'], ['user', 'supervisor', 'admin']]
        )

        for (const property in this.links) {
          let link = this.links[property];
          // Hide links that are needing auth
          if(link.auth && this.auth || !link.auth && !this.auth || link.auth === 'both') {
            // Hide Admin links form supervisors
            // localLinks.push(link);
            if(!link.role || roles.includes(link.role) || (!link.auth && !this.auth || link.auth === 'both')) {
              /* if( !link.role || (link.role == this.user.role || ((link.role == "user" && this.user.role == "supervisor") || (link.role == "supervisor" && this.user.role == "admin")))) { */
              localLinks.push(link);
            }
          }
          }
        return localLinks
        //return this.links.filter(x => { if((x.auth && this.auth || !x.auth && !this.auth || x.auth === 'both') && ( !x.admin || x.admin && (this.user.role == 'admin'))) return x})
      }
    },
    menuLocal: {
      get () { return this.menu },
      set () { /* this.$emit('menu', value) */ },
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style scoped>
  .v-tooltip__content.menuable__content__active {
      opacity: 1 !important;
    }
</style>

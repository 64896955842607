<template>
  <v-container
    fluid
    class="fill-height d-flex pb-16 mb-0"
    style="align-content: flex-start; flex-direction: row; height:calc(100vh - 64px)"
  >
      <v-row v-if="$vuetify.breakpoint.smAndUp">
        <v-col class="pb-0" cols="12">
          <div class="overline primary--text">{{ $t('Fundus') }}</div>
        </v-col>
      </v-row>
      <v-row :class="$vuetify.breakpoint.xsOnly ? 'pt-2' : ''">
        <v-col class="l-2 pt-0 pb-0 pb-sm-2" cols="12">
          <Search
            :loadNextTrigger="loadNextTrigger"
            @openCategory="categoryDialog = !categoryDialog"
            @loading="loading = true"
            @loaded="loading = false"
            @onlyCurrentLanguage="onlyCurrentLanguage = $event"
            @resultsFound="resultsFound = $event"
          ></Search>
        </v-col>
      </v-row>

      <v-row 
        :style="$vuetify.breakpoint.smAndUp ? 'height: calc(100vh - 210px)':'height: calc(100vh - 144px)'"
      >
        <!-- CATEGORY COLUMN -->
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          cols="12" sm="12" md="3" lg="3" xl="2"
          class="pl-2 pt-1 fill-height overflow-y-auto"
        >
          <!-- style="overflow-y: auto; height:fit-content" -->
          <Categories
            :showSearch="false"
            :auth="auth" :user="user" :settings="settings"
            :categories="categoriesList"
            :openCategories="categories"
            @clickedCategory="resetSearch(); tagFilter = []"
          ></Categories>
        </v-col>

        <!-- MOBILE CATEGORY DIALOG -->
        <v-dialog
          v-else
          v-model="categoryDialog"
          fullscreen
          scrollable
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              color="primary"
              class="white--text"
              dense
            >
              <v-icon class="mr-2 white--text">
                mdi-file-tree
              </v-icon>
              <v-toolbar-title>{{$t('Categories')}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  icon
                  color="secondary"
                  @click="categoryDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text class="pa-4 pl-0" style="height:100%">
              <Categories
                :showSearch="true"
                :auth="auth" :user="user" :settings="settings"
                :categories="categoriesList"
                @clickedCategory="categoryDialog = false; resetSearch(); tagFilter = []"
                :openCategories="categories"
              ></Categories>
            </v-card-text>
          </v-card>
        </v-dialog>
        
        <!-- LOADING -->
        <v-col
          v-if="loading && !propId"
          class="fill-height text--center overflow-y-auto pt-16 pr-2 pb-12 pl-md-0 pl-2" cols="12" sm="12" md="9" lg="9" xl="10"
        >
        <Loader classes="mt-16" :interval="444"></Loader>
        </v-col>
        
        <!-- SEARCH QUERY RESULTS -->
        <v-col
          v-else-if="searchResults.length || searchQuery.length"
          class="fill-height overflow-y-auto pt-1 pr-2 pb-12 pl-md-0 pl-2" cols="12" sm="12" md="9" lg="9" xl="10"
        >
          <div class="overline primary--text">
            {{ $t('Search results for') }} '{{ searchQuery }}':
          </div>

          <v-card
            v-if="Object.keys(categoriesFlat).length && searchQuery.length > 2 && searchInCategories.length"
            class="pt-2 px-3 pb-3 mb-6"
          >
            <h2>
              {{  $t('Category results') }}
              <v-chip dense small class="absolute right mr-2">
                {{ searchInCategories.length }}
              </v-chip>
            </h2>

            <hr class="mb-4 op-12">

            <p class="ma-0 ml-3">
              <router-link
                v-for="path in searchInCategories"
                :key="`paths-${path.join()}`"
                :to="`/db/${path.join('/')}`"
                class="pointer no-underline mr-4 inlineBlock"
              >
                <!-- :class="catIndex != categories.length - 1 ? 'no-underline' : 'secondary--text'" -->
                <!-- :class="catIndex != categories.length - 1 ? 'primary--text' : 'secondary--text'" -->
                <v-icon x-small class="secondary--text"> <!-- :class="!Object.keys(item.children).length ? 'secondary--text' : 'primary--text'"> -->
                  mdi-{{ categoriesFlat[path[path.length-1]].icon && categoriesFlat[path[path.length-1]].icon !== '-' ? categoriesFlat[path[path.length-1]].icon : "rhombus-medium" }}
                </v-icon>
                {{ categoriesFlat[path[path.length-1]].name[$i18n.locale] }}
              </router-link>
            </p>
            <!-- <hr>
            <code class="block">
              {{ categoriesList }}
            </code> -->
          </v-card>

          <v-card v-if="searchResults.length" class="pt-2 px-3 pb-3 mb-6">
            <h2>
              {{ $t('Prop results') }}
              <v-chip dense small class="absolute right mr-2">
                {{ resultsFound }}<!-- 
                -->{{ $t('pcs') }}
              </v-chip>
            </h2>

            <hr class="mb-4 op-12">

            <!-- LIST VIEW TOGGLE -->
            <v-btn class="primary white--text mr-2" v-if="$vuetify.breakpoint.smAndUp" icon @click="toggleListView()">
              <v-icon>
                mdi-{{ listView ? 'view-module' : 'view-list' }}
              </v-icon>
            </v-btn>

            <!-- UI SLIDER -->
            <v-btn class="primary white--text mr-2" v-if="$vuetify.breakpoint.mdAndUp && !showCardSizeSlider" icon @click="showCardSizeSlider = true">
              <v-icon>
                mdi-resize-variant
              </v-icon>
            </v-btn>
            <v-chip
              v-if="$vuetify.breakpoint.mdAndUp && showCardSizeSlider"
              class="grey px-2 mr-2"
              :class="$vuetify.theme.dark ? 'darken-3' : 'lighten-3'"
              style="width:333px; overflow: visible; height: 36px; border-radius: 18px;"
            >
              <v-slider
                v-model="cardSize"
                @change="$helpers.writeLocalStorage('cardSize', cardSize)"
                dense
                min="100"
                max="450"
                step="20"
                style="width:333px;"
                class="mt-4"
                prepend-icon="mdi-resize-variant"
                @click:prepend="showCardSizeSlider = false"
                thumb-label
              >
                <!-- append-icon="mdi-square" -->
                <template v-slot:thumb-label="{ value }">
                  <v-icon small>
                    mdi-{{ ['view-grid-compact', 'view-comfy', 'dots-grid', 'view-grid', 'square'][Math.min(Math.floor((value-100) / 70), 4)] }}
                  </v-icon>
                </template>
              </v-slider>
            </v-chip>
          </v-card>

          <div v-if="resultsFound" ref="propResults">
            <Proplist
              v-for="propId in searchResults"
              :user="user"
              :listView="listView || $vuetify.breakpoint.xs"
              :showTags="listView && $vuetify.breakpoint.smAndUp"
              :key="propId"
              :propIndex="propId"
              :propId="propId"
              :cardSize="cardSize"
              cardClasses="mb-4"
              :categories="categories"
            ></Proplist>

            <v-hover
              v-slot="{ hover }"
              v-if="searchResults.length !== resultsFound"
            >
              <v-btn
                :width="`${cardSize - 8}px`"
                :height="`${cardSize - 8}px`"
                class="mr-2 mb-2"
                :class="hover ? 'secondary' : ''"
                @click="loadNextTrigger = $helpers.createUid();"
              >
                <div
                  style="white-space: normal;"
                >
                  <p
                    class=""
                    :class="hover ? 'shake-infinite' : ''"
                  >
                    <v-icon
                      large
                      :class="hover ? 'rot--45' : ''"
                    >mdi-rocket-launch</v-icon>
                  </p>
                  {{ `${$t('Load')} ${Math.min(75, resultsFound-searchResults.length)} ${$t('more')}`}}..
                </div>
              </v-btn>
            </v-hover>
          </div>
          <div v-else class="text--center pt-16">
            <span class="grey--text">
              <v-icon x-large class="op-12 my-4 pop-in-fancy">mdi-emoticon-confused</v-icon>
              <br>
              {{ $t('No props for this search') }}.
            </span>
          </div>
        </v-col>
        
        <!-- PROP IN DETAIL -->
        <v-col
        v-else-if="propId"
        class="fill-height overflow-y-auto pt-1 pr-2 pb-12 pl-md-0 pl-2" cols="12" sm="12" md="9" lg="9" xl="10"
        >
          <!-- BREADCRUMPS -->
          <Breadcrumbs
            v-if="!searchResults.length && !searchQuery.length"
            :categories="categories" 
            :categoriesFlat="categoriesFlat"
            @openCategoryChooser="categoryDialog = true"
          ></Breadcrumbs>

          <Prop
            :user="user"
            :propId="propId"
          ></Prop>
        </v-col>

        <!-- CATEGORY: LIST OF PROPS -->
        <v-col
          v-else-if="category && category.name"
          class="fill-height overflow-y-auto pt-1 pr-2 pb-12 pl-md-0 pl-2"
          cols="12" sm="12" md="9" lg="9" xl="10"
        >
          <!-- BREADCRUMPS -->
          <Breadcrumbs
            v-if="!searchResults.length && !searchQuery.length"
            :categories="categories" 
            :categoriesFlat="categoriesFlat"
            @openCategoryChooser="categoryDialog = true"
          ></Breadcrumbs>

          <v-card class="pt-2 px-3 pb-3 mb-6">
            <h2>
              <span class="grey--text">
                {{ $t('Category') }}:
              </span>
              <v-icon>
                mdi-{{ category.icon && category.icon !== '-' ? category.icon : "rhombus-medium" }}
              </v-icon>
              {{ category.name[$i18n.locale] }}

              <div class="inlineBlock" :class="$vuetify.breakpoint.smAndDown ? '' : 'absolute right top mr-2 mt-1'">
                <v-chip
                  color="error"
                  v-if="(category.amountOfProps - Object.keys(filteredCategoryProps).length) > 0"
                  @click="showTagFilters = true; $refs['resetFilters'].$el.classList.toggle('wiggle')"
                  dense
                  small
                >
                  {{ $helpers.plural(category.amountOfProps - Object.keys(filteredCategoryProps).length, [$t('hidden prop'), $t('hidden props')]) }}
                </v-chip>
                <v-chip
                  v-if="(Object.keys(categoriesFlat).length && childCategories.length) == 0"
                  dense
                  small 
                  class="ml-2"
                >
                  {{ $helpers.plural(category.amountOfProps, [$t('Prop'), $t('Props')]) }}
                </v-chip>
              </div>
            </h2>

            <!-- Hide filters card size rsldier and listView etc when seeing a sub list with categories -->
            <div v-if="(Object.keys(categoriesFlat).length && childCategories.length) == 0 && category.amountOfProps > 0">
              <hr class="mb-4 op-12">

              <!-- LIST VIEW TOGGLE -->
              <v-btn class="primary white--text mr-2" v-if="$vuetify.breakpoint.smAndUp" icon @click="toggleListView()">
                <v-icon>
                  mdi-{{ listView ? 'view-module' : 'view-list' }}
                </v-icon>
              </v-btn>

              <!-- UI SLIDER -->
              <v-btn class="primary white--text mr-2" v-if="$vuetify.breakpoint.mdAndUp && !showCardSizeSlider" icon @click="showCardSizeSlider = true">
                <v-icon>
                  mdi-resize-variant
                </v-icon>
              </v-btn>
              <v-chip
                v-if="$vuetify.breakpoint.mdAndUp && showCardSizeSlider"
                class="grey px-2 mr-2"
                :class="$vuetify.theme.dark ? 'darken-3' : 'lighten-3'"
                style="width:333px; overflow: visible; height: 36px; border-radius: 18px;"
              >
                <v-slider
                  v-model="cardSize"
                  @change="$helpers.writeLocalStorage('cardSize', cardSize)"
                  dense
                  min="100"
                  max="450"
                  step="20"
                  style="width:333px;"
                  prepend-icon="mdi-resize-variant"
                  @click:prepend="showCardSizeSlider = false"
                  thumb-label
                  hide-details
                  >
                  <!-- append-icon="mdi-square" -->
                  <template v-slot:thumb-label="{ value }">
                    <v-icon small>
                      mdi-{{ ['view-grid-compact', 'view-comfy', 'dots-grid', 'view-grid', 'square'][Math.min(Math.floor((value-100) / 70), 4)] }}
                    </v-icon>
                  </template>
                </v-slider>
              </v-chip>

              <!-- FILTER FOR TAGS -->
                <!-- :class="$vuetify.theme.dark ? 'darken-3' : 'lighten-3'" -->
              <v-btn
                @click="showTagFilters = !showTagFilters"
                icon
                class="primary pa-0 ma-0"
                style="min-width:initial;"
                :style="tagFilter.length || baujahrHashesSelected.length ? 'border-radius: 666px 0 0 666px' : ''"
              >
                <v-icon class="white--text">
                  <!-- mdi-{{ showTagFilters ? 'tag-remove' : 'tag-search' }} -->
                  mdi-tag-search
                </v-icon>
              </v-btn>

              <v-btn
                v-if="tagFilter.length || baujahrHashesSelected.length"
                ref="resetFilters"
                @click="resetBaujahr(); showTagFilters=false; tagFilter = []"
                text
                class="secondary px-2 ma-0"
                style="background-color:initial; border-radius: 0 666px 666px 0"
              >
                <span class="small">
                  {{ $t('Reset filters') }}
                </span>
                <v-icon small class="ml-1">mdi-backspace</v-icon>
              </v-btn>

              <!-- TAGS FOR THIS CATEGORY -->
              <!-- <code>{{ category.tags_in_category }}</code> -->
              <div
                v-if="showTagFilters && category.tags_in_category && Object.keys(category.tags_in_category).length"
                class="pt-2"
                :style="$vuetify.breakpoint.lgAndUp ? 'column-count: 2; column-gap: 0.5rem;' : ''"
              >
                <!-- tagFilter: <code class="block">{{ tagFilter }}</code> -->
                <!-- availableTags: <code class="block">{{ availableTags }}</code> -->
                <div
                  v-for="(tags, tag_category, index) in availableTags"
                  :key="tag_category+index"
                  dense
                  class="pa-2 pt-3 pb-0 mb-2 relative hoverList rounded"
                  :style="tags[Object.keys(tags)[0]]['original_custom_key'] === 'custom_14' ? 'column-span: all;' : ''"
                >
                  <!-- Baujahr range slider -->
                  <div
                    class="pb-1"
                    v-if="tags[Object.keys(tags)[0]]['original_custom_key'] === 'custom_14'"
                  >
                  <!-- <code>{{ baujahrHashesSelected }}</code>
                  <hr>
                  <code>{{ tagFilter }}</code> -->
                  <div class="small relative top fill-width">
                    <v-icon x-small class="op-50">mdi-tag</v-icon>
                    {{ tags[Object.keys(tags)[0]]["tag_category"][$i18n.locale] }}
                    <span class="grey--text">-</span>
                    {{ baujahrLabel }}
                    <Info 
                      v-if="baujahrSelector[0] != minBaujahr || baujahrSelector[1] != maxBaujahr"
                      :title="$t('About `Year of construction`')"
                      :text="$t('Not all props have an attributed production year.<br>If you select any year, the props without one get hidden.')"
                      x_small
                      position="top"
                      opacity="1"
                      btnClasses="primary ml-1 nudge-y--12"
                      iconClasses="white--text"
                    ></Info>
                  </div>
                    <v-chip
                      class="grey px-3 pt-1 block"
                      :class="$vuetify.theme.dark ? 'darken-3' : 'lighten-3'"
                      style="overflow: visible; height: 36px; border-radius: 18px;"
                    >
                      <v-range-slider
                        class="baujahr absolute"
                        v-model="baujahrSelectorRangeValue"
                        @change="baujahrSelector = baujahrSelectorRangeValue"
                        hide-details
                        dense
                        :step="10"
                        :min="minBaujahr"
                        :max="maxBaujahr"
                        :tick-labels="baujahrTickLabels"
                        ticks="always"
                        tick-size="2"
                        style="width:100%;"
                        append-icon="mdi-backspace"
                      >
                        <template v-slot:prepend v-if="$vuetify.breakpoint.smAndUp">
                          <span class="pt-1">
                            {{ $t('Antique') }}
                          </span>
                        </template>
                        <template v-slot:append>
                          <div class="pr-4" style="height:2em;">
                            <span v-if="$vuetify.breakpoint.smAndUp">
                              {{ $t('Contemporary') }}
                            </span>
                            <v-btn
                              :disabled="baujahrSelectorRangeValue[0] === minBaujahr && baujahrSelectorRangeValue[1] === maxBaujahr"
                              icon small @click="resetBaujahr()" class="secondary--text nudge-y--12"
                            >
                              <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <template v-slot:thumb-label="{ value }">
                          {{ value ==  minBaujahr ? '←' : value ==  maxBaujahr ? '→' : value }}
                        </template>
                      </v-range-slider>
                    </v-chip>
                  </div>

                  <!-- Get category name from first tag in list -->
                  <div class="small absolute top capitalize fill-width" v-else>
                    <v-icon x-small class="op-50">mdi-tag</v-icon>
                    {{ tags[Object.keys(tags)[0]]["tag_category"][$i18n.locale] }}
                  </div>

                  <!-- <code>{{ baujahrSelector }}</code>
                  <code>{{ baujahrHashesSelected }}</code> -->
                  <!-- {{ index }} - 
                  {{  tag_category }} -
                  {{  Object.keys(tags)[0] }}
                  <code>{{ tags }}</code> -->

                  <!-- tags {{ tags }} -->
                  <!-- column -->

                  <v-chip-group
                    v-if="tags[Object.keys(tags)[0]]['original_custom_key'] !== 'custom_14'"
                    v-model="tagFilter"
                    multiple
                    show-arrows
                    active-class="op-100"
                  >
                    <v-chip
                      v-for="(tag, md5, indexTag) in tags"
                      :key="tag_category+md5+indexTag"
                      class="capitalize black--text mr-2"
                      :class="tagFilter.includes(md5) ? 'pr-1' : ''"
                      :style="tagFilter.length ? 'opacity:0.35' : ''"
                      small
                      :color="tag.color"
                      :value="md5"
                    >
                      <!-- {{ tag }} -->
                      {{ tag.name[$i18n.locale] }}
                      <!-- {{ tag.tag_category[$i18n.locale] }}: -->
                      <v-icon v-if="tagFilter.includes(md5)" small color="primary" class="ml-1">mdi-check-circle</v-icon>
                    </v-chip>
                  </v-chip-group>
                </div>
              </div>
            </div>
          </v-card>
          <!-- FILTERS RESULT IN NO OBJECT BEING DISPLAYED -->
          <div v-if="category.amountOfProps > 0 && Object.keys(filteredCategoryProps).length === 0" class="text--center pt-16">
            <span class="grey--text">
              <v-icon x-large class="op-12 my-4 shake">mdi-tag-multiple</v-icon>
              <br>
              {{ $t('No props for you because of your set filters.') }}
              <br>
              <br>
              <v-btn color="secondary" @click="resetBaujahr(); showTagFilters=false; tagFilter = []">
                <v-icon>mdi-tag-remove</v-icon>
                {{ $t('Reset filters') }}
              </v-btn>
            </span>
          </div>
          <div v-if="category.amountOfProps">
            <!-- TEST MISSING/LOADING -->
            <!-- <Proplist
              :user="user"
              :listView="listView || $vuetify.breakpoint.xs"
              :propId="666"
              :cardSize="cardSize"
              :categories="categories"
            ></Proplist>
            <Proplist
              :user="user"
              :listView="listView || $vuetify.breakpoint.xs"
              :cardSize="cardSize"
              :categories="categories"
            ></Proplist> -->
            <!-- tagFilter -->
            <!-- v-for="(prop, propIndex) in category.props.filter(prop => tagFilter.every(tag => prop.tag_hashes.includes(tag)))" -->
            <!-- v-for="(prop, propIndex) in Object.values(category.props).filter(prop => tagFilter.every(tag => prop.tag_hashes.includes(tag)))" -->
            <Proplist
              v-for="(prop, propIndex) in filteredCategoryProps"
              :user="user"
              :listView="listView || $vuetify.breakpoint.xs"
              :showTags="listView && $vuetify.breakpoint.smAndUp"
              :key="propIndex"
              :propIndex="parseInt(propIndex)"
              :propData="prop"
              :cardSize="cardSize"
              :categories="categories"
            ></Proplist>
          </div>

          <!-- CHILD CATEGORIES -->
          <div v-else-if="Object.keys(categoriesFlat).length && childCategories.length">
            <!-- {{ $t('Subcategories') }}:
            <br> -->
            <!-- {{ childCategories }} -->
            <div
              v-for="path in childCategories"
              :key="`paths-${path.join()}`"
              class="my-1"
            >
              <router-link
                :to="`/db/${path.join('/')}`"
                class="inlineBlock pointer no-underline mr-4 py-1"
                :class="[$vuetify.breakpoint.smAndDown ? '': 'hover', $vuetify.theme.dark ? 'white--text ' : 'black--text']"
              >
                <!-- :class="catIndex != categories.length - 1 ? 'no-underline' : 'secondary--text'" -->
                <!-- :class="catIndex != categories.length - 1 ? 'primary--text' : 'secondary--text'" -->
                <v-icon x-small class="primary--text"> <!-- :class="!Object.keys(item.children).length ? 'secondary--text' : 'primary--text'"> -->
                  mdi-{{ categoriesFlat[path[path.length-1]].icon && categoriesFlat[path[path.length-1]].icon !== '-' ? categoriesFlat[path[path.length-1]].icon : "rhombus-medium" }}
                </v-icon>
                {{ categoriesFlat[path[path.length-1]].name[$i18n.locale] }}
                <v-chip dense x-small class="px-1">
                  {{ categoriesFlat[path[path.length-1]].amountOfProps }}
                </v-chip>
              </router-link>
            </div>
          </div>
          <div v-else class="text--center pt-16">
            <span class="grey--text">
              <v-icon x-large class="op-12 my-4 shake">mdi-emoticon-confused</v-icon>
              <br>
              {{ $t('No props catalogued in this category') }}.
              <br>
              {{ $t("This does not mean we don't have any! Stop by and ask.") }}
            </span>
          </div>
          <!-- <code class="block" style="white-space:pre; max-height: 420px; overflow: auto;">{{ category }}</code> -->
        </v-col>

        <!-- NOTHING SELECTED -->
        <v-col
          v-else
          class="fill-height overflow-y-auto pt-16 pr-2 pb-12 pl-md-0 pl-2" cols="12" sm="12" md="9" lg="9" xl="10"
        >
          <div class="text--center">
            <span class="grey--text">
              <v-icon x-large class="op-12 my-4 pop-in-fancy">mdi-flare</v-icon>
              <br>
              {{  $t('Nothing selected.') }}
              <br>
              {{  $t('Choose category or do a search.') }}
            </span>
          </div>
        </v-col>
      </v-row>

      <!-- FOOTER -->
      <!-- <v-row>
        <v-col  class="pl-2 pt-2 grey--text small text--center" cols="12">
        </v-col>
      </v-row> -->
    </v-container>
</template>

<script>
/* import LanguageSwitcher from "./LanguageSwitcher.vue"
import BrightnessSwitcher from "./BrightnessSwitcher.vue" */
import axios from 'axios';
import Categories from "../components/Categories.vue"
import Breadcrumbs from "../components/Breadcrumbs.vue"
import Prop from "../components/Prop.vue"
import Proplist from "../components/Proplist.vue"
import Search from "../components/Search.vue"
import Loader from "../components/Loader.vue"
import Info from '@/components/Info'
import { mapState } from 'vuex'

export default {
  name: 'Productions',
  props: {
    auth: Boolean,
    user: Object,
    settings: Object,
    propId: Number,
    categories: Array,
  },
  components: {
    /* LanguageSwitcher,
    BrightnessSwitcher */
    Categories,
    Breadcrumbs,
    Prop,
    Proplist,
    Search,
    Loader,
    Info,
  },
  data() {
    return {
      /* on: false,
      attrs: false, */
      propData: [],
      // files: [],
      category: [],
      loading: true,
      listView: false,
      categoryDialog: false, 
      showCardSizeSlider: false, 
      searchQuery: '',
      onlyCurrentLanguage: '',
      resultsFound: 0,
      loadNextTrigger: '',
      searchResults: [],
      categoriesList: [],
      categoriesFlat: {},
      cardSize: 220,
      showTagFilters: false,
      tagFilter: [],
      minBaujahr: 1890,
      maxBaujahr: 2030,
      baujahrSelectorRangeValue: [this.minBaujahr, this.maxBaujahr],
      baujahrSelector: [this.minBaujahr, this.maxBaujahr],
      baujahrTickLabels: ["\n","\n1900", "\n", "\n", "\n", "\n", "\n1950", "\n", "\n", "\n", "\n", "\n2000", "\n", "\n", "\n"],
      baujahrTickLabelsReset: ["\n","\n1900", "\n", "\n", "\n", "\n", "\n1950", "\n", "\n", "\n", "\n", "\n2000", "\n", "\n", "\n"],
      baujahrHashesListComplete: {
        "1890": 'c4ca4238a0b923820dcc509a6f75849b', // hash of 1 "antik" bzw 1890
        "1900": '9fdb62f932adf55af2c0e09e55861964',
        "1910": 'ab7314887865c4265e896c6e209d1cd6',
        "1920": '44968aece94f667e4095002d140b5896',
        "1930": '29530de21430b7540ec3f65135f7323c',
        "1940": '95e6834d0a3d99e9ea8811855ae9229d',
        "1950": '03e7d2ebec1e820ac34d054df7e68f48',
        "1960": '7f16109f1619fd7a733daf5a84c708c1',
        "1970": '0004d0b59e19461ff126e3a08a814c33',
        "1980": 'f80bf05527157a8c2a7bb63b22f49aaa',
        "1990": 'dc513ea4fbdaa7a14786ffdebc4ef64e',
        "2000": '08f90c1a417155361a5c4b8d297e0d78',
        "2010": 'd7a84628c025d30f7b2c52c958767e76',
        "2020": '7b7a53e239400a13bd6be6c91c4f6c4e',
      },
    }
  },
  watch: {
    categoryDialog: {
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        if(newVal) {
          // dialog has opened - set history marker
          let query = { ...this.$route.query, dialog: 'true' };
          if(JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
            this.$router.push({ query });
          }
        } else {
          if('dialog' in this.$route.query) {
            const query = { ...this.$route.query };
            delete query.dialog;
            this.$router.push({ query });
          }
        }
      },
      immediate: true,  // Run the watcher on component load to catch the initial value
    },
    '$route.query.dialog': {
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        // open or close the dialog when navigated
        this.categoryDialog = newVal;
      },
      immediate: true,  // Run the watcher on component load to catch the initial value
    },
    '$route.query.q': {
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        // console.log(`Parameter 'a' changed from ${oldVal} to ${newVal}`);
        // Add any additional logic you want to run when 'a' changes
        if(newVal) {
          this.searchQuery = decodeURIComponent(this.$route.query.q);
        } else {
          this.searchQuery = '';
        }
      },
      immediate: true,  // Run the watcher on component load to catch the initial value
    },
    '$route.query.a': {
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        // console.log(`Parameter 'a' changed from ${oldVal} to ${newVal}`);
        // Add any additional logic you want to run when 'a' changes
        if(newVal) {
          this.searchResults = newVal.split(',').map(Number);
        } else {
          this.searchResults = [];
        }
      },
      immediate: true,  // Run the watcher on component load to catch the initial value
    },
    '$route.query.tags': {
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        if(newVal) {
          this.showTagFilters = true;
          this.tagFilter = [...newVal.split(','), ...this.tagFilter]
          this.getBaujahrFromLocalStorage();
          // Trigger the filtration process
          this.baujahrSelector = this.baujahrSelectorRangeValue;

          let targetQuery = { ...this.$route.query };
          delete targetQuery.tags;
          if(JSON.stringify(this.$route.query) !== JSON.stringify(targetQuery)) {
            this.$router.replace(
              {
                query: targetQuery
              }
            );
          }
        }
      },
      immediate: true,  // Run the watcher on component load to catch the initial value
    },
    categories: {
      immediate: true,
      handler() {
        this.fetchLastCategory();
      },
    },

    showTagFilters() {
      if(this.showTagFilters) {
        // Apply the filter if anything in local storage
        this.getBaujahrFromLocalStorage();
        // Trigger the filtration process
        this.baujahrSelector = this.baujahrSelectorRangeValue;
      /* } else {
        this.tagFilter = [];
        this.resetBaujahr(); */
      }
    },
    baujahrSelector() {
      // 
      const [min, max] = this.baujahrSelector;
      if(min === this.minBaujahr && max === this.maxBaujahr) {
        this.$helpers.clearLocalStorageItem('minYear');
        this.$helpers.clearLocalStorageItem('maxYear');
      } else {
        this.$helpers.writeLocalStorage('minYear', min);
        this.$helpers.writeLocalStorage('maxYear', max);
      }
    },
  },

  computed: {
    ...mapState(['metadata']),
    baujahrHashesSelected() {
      // Returns the hashes from the baujahr range slider min-max and everything in between
      const [min, max] = this.baujahrSelector; // Destructure min and max values
      if(min === this.minBaujahr && max === this.maxBaujahr) {
        return []  // Don't select any if its maxed out to preserve props without baujahr value
      } else {
        // Filter years in range and return their hash values as an array
        return Object.keys(this.baujahrHashesListComplete)
          .filter(year => year >= min && year <= max)
          .map(year => this.baujahrHashesListComplete[year]); // Map to the hash values only
      }
    },
    baujahrLabel() {
      if (this.baujahrSelector[0] === this.minBaujahr && this.baujahrSelector[1] === this.minBaujahr) {
        return this.$t('Only antiques');
      } 
      if (this.baujahrSelector[0] === this.maxBaujahr && this.baujahrSelector[1] === this.maxBaujahr) {
        return this.$t('Only contemporary');
      } 
      if (this.baujahrSelector[0] === this.baujahrSelector[1]) {
        return `${this.$t('Around')} ${this.baujahrSelector[0]}`;
      } 
      if (this.baujahrSelector[0] !== this.minBaujahr || this.baujahrSelector[1] !== this.maxBaujahr) {
        const fromLabel = this.baujahrSelector[0] === this.minBaujahr ? this.$t('Antique') : this.baujahrSelector[0];
        const toLabel = this.baujahrSelector[1] === this.maxBaujahr ? this.$t('Contemporary') : this.baujahrSelector[1];
        return `${this.$t('From')} ${fromLabel} ${this.$t('to')} ${toLabel}`;
      }
      return this.$t('From all ages');
    },
    filteredCategoryProps() {
      // onload, just return this.category.props until everything else is loaded
      // also if no filte ractive, just return the regular list
      // if(!this.showTagFilters || (!this.baujahrHashesSelected.length && !this.tagFilter.length)) {
      if(!this.baujahrHashesSelected.length && !this.tagFilter.length) {
        return this.category.props
      }
      return Object.entries(this.category.props)
        // eslint-disable-next-line no-unused-vars
        .filter(([key, prop]) => 
          // Does not include years - match every selected tag
          (!this.tagFilter.length  || this.tagFilter.every(tag => prop.tag_hashes.includes(tag)))
          &&
          // if any year is selected, match at least one of them
          (!this.baujahrHashesSelected.length || this.baujahrHashesSelected.some(hash => prop.tag_hashes.includes(hash)))
        )
        .reduce((acc, [key, prop]) => {
          acc[key] = prop;
          return acc;
      }, {});
    },

    childCategories() {
      const findCategoryById = (categories, targetId) => {
        for (const category of categories) {
          if (category.id === targetId) {
            return category; // Return the category if id matches
          }
          if (category.children && category.children.length) {
            const found = findCategoryById(category.children, targetId); // Recursively search in children
            if (found) return found;
          }
        }
        return null; // Return null if not found
      };
      const targetCategory = findCategoryById(this.categoriesList, this.category.id);
      return targetCategory ? targetCategory.children.map(child => child.idPath) : [];
    },

    availableTags() {
      // TODO: maybe this can return only the tags that are available from the currently viewed props?
      // so only show the tags that are in the current selection
      const tags = this.category.tags_in_category;
      const groupedTags = {};
      // Reset to remove all tick marks
      this.resetBaujahrTickLabels();
      // Group tags by category
      Object.entries(tags).forEach(([key, value]) => {
        // const category = value.tag_category[this.$i18n.locale];
        const category = value.tag_category['de'];  // Gets sorted by DE names of fields!
        // Initialize the category if it doesn't exist
        if (!groupedTags[category]) {
          groupedTags[category] = {};
        }
        // Add tick marks if Baujahr
        if(value.original_custom_key && value.original_custom_key == 'custom_14') {
          let index = Object.keys(this.baujahrHashesListComplete).indexOf(value.name.de)  /// is translated but only numbers
          // Reset "1" to antik
          if(value.name.de === '1') index = 0;
          if(index >= 0) {
            // maybe ⦿ or big ⬤ or medium emoji ⚪ or weird ⟟ or ◆
            if(!this.baujahrTickLabels[index].includes('◆')) {
              /* this.baujahrTickLabels[index] = this.baujahrTickLabels[index].replace('◆', '~❖~');  // multiple
            } else { */
              this.baujahrTickLabels[index] = "◆"+this.baujahrTickLabels[index];
            }
          }
        }
        // Add the tag under the category
        groupedTags[category][key] = value;
      });

      // Sort groupedTags alphabetically by category name
      return Object.fromEntries(
        Object.entries(groupedTags).sort(([a], [b]) => a.localeCompare(b))
      );
    },

    searchInCategories() {
      const search = this.$helpers.normalizeText(this.searchQuery);
      const findMatches = (items) => {
        let result = [];
        items.forEach(item => {
          // Check if the search query is in any of the name fields (de, en, fr)
          // if onlyCurrentLanguage is empty, else check only that language
          const matches = this.onlyCurrentLanguage
            ? this.$helpers.normalizeText(item.name[this.onlyCurrentLanguage] || '').includes(search)
            : Object.values(item.name).some(name => 
                this.$helpers.normalizeText(name).includes(search)
            );
          // If a match is found, add the idPath to the result
          if (matches) {
            result.push(item.idPath);
          }
          // Recursively search children
          if (item.children && item.children.length > 0) {
            result = result.concat(findMatches(item.children));
          }
        });
        return result;
      };
      // Start the search from the top-level items
      return findMatches(this.categoriesList); // assuming your top-level data is in `this.categories`
    }
  },

  async created() {
    // console.log('Route params:', this.$route.params);
    this.loading = true;
    try {
      const response = await axios.get(`categories/_categories.json?v=${encodeURIComponent(this.metadata.date_of_collection)}`);
      this.categoriesList = response.data;
      const responseCat = await axios.get(`categories/_categories-raw.json?v=${encodeURIComponent(this.metadata.date_of_collection)}`);
      this.categoriesFlat = responseCat.data;
    } catch (error) {
      // console.error('Failed to load categories:', error);
      this.categoriesList  = null;
      this.categoriesFlat  = null;
    }
    // Set listView from localStorage
    this.listView = this.$helpers.readLocalStorage('listView');
    
    // Set cardSize from localStorage
    this.cardSize = this.$helpers.readLocalStorage('cardSize') || this.cardSize;
    
    // Set Baujahr range slider from localStorage
    this.getBaujahrFromLocalStorage();

    this.loading = false;
  },

  methods: {
    toggleListView() {
      this.listView = !this.listView;
      this.$helpers.writeLocalStorage('listView', this.listView);
    },

    resetSearch() {
      this.searchQuery='';
      this.searchResults=[];
      let targetQuery = { ...this.$route.query };
      delete targetQuery.q;  // Remove the 'q' parameter if it exists
      delete targetQuery.a;  // Remove the 'a' parameter if it exists

      if(JSON.stringify(this.$route.query) !== JSON.stringify(targetQuery)) {
        this.$router.replace(
          {
            query: targetQuery
          }
        );
      }
    },

    async fetchLastCategory() {
      let categoryId = this.categories[this.categories.length - 1];
      if(!categoryId) {
        this.category = null;
        return;
      }

      this.loading = true;
      try {
        const response = await axios.get(`categories/${categoryId}.json?v=${encodeURIComponent(this.metadata.date_of_collection)}`);
        this.category = response.data;
      } catch (error) {
        // console.error('Failed to load prop details:', error);
        this.category = null;
      } finally {
        this.loading = false;
      }
    },


    getBaujahrFromLocalStorage() {
      this.baujahrSelector[0] = this.$helpers.readLocalStorage('minYear') || this.minBaujahr;
      this.baujahrSelector[1] = this.$helpers.readLocalStorage('maxYear') || this.maxBaujahr;
      this.baujahrSelectorRangeValue[0] = this.$helpers.readLocalStorage('minYear') || this.minBaujahr;
      this.baujahrSelectorRangeValue[1] = this.$helpers.readLocalStorage('maxYear') || this.maxBaujahr;
      // baujahrHashesSelected is not updated
    },

    resetBaujahr() {
      this.baujahrSelector = [this.minBaujahr,this.maxBaujahr];
      this.baujahrSelectorRangeValue = [this.minBaujahr,this.maxBaujahr];
    },

    resetBaujahrTickLabels() {
      this.baujahrTickLabels = JSON.parse(JSON.stringify(this.baujahrTickLabelsReset));
    },

    hasKey(obj, keyToFind) {
      for (const key in obj) {
        if (key === keyToFind) {
          return true;
        }
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (this.hasKey(obj[key], keyToFind)) {
            return true;
          }
        }
      }
      return false;
    }
  },
};
</script>

<style>
.baujahr.v-input--range-slider .v-input__prepend-outer,
.baujahr.v-input--range-slider .v-input__append-outer {
  white-space: nowrap;
}
.baujahr.v-input--range-slider .v-slider__tick-label::first-line {
  font-size: 1.4em;
  color: #9c27b0;  /* secondary light mode */
}
.theme--dark .baujahr.v-input--range-slider .v-slider__tick-label::first-line {
  color: #03dac6;  /* secondary dark mdoe */
}
.baujahr.v-input--range-slider .v-slider__tick-label {
  font-size: .7em;
  transform: translate(-47%, -1.2em) !important;  /* translate(-50%, -.65em) !important; */
  white-space: pre;
  line-height:1em;
  text-align: center;
  z-index:1;
}
</style>
<template>
    <!-- SIGNUP FORM -->
  <v-card
    class="transparent"
    flat
    :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-4' : 'mx-auto my-4 mt-12 pa-8'"
    :max-width="$vuetify.breakpoint.smAndUp ? 600 : ''"
  >
    <div class="overline primary--text pl-0">
      {{ $t('Sign up for club members') }}
    </div>
    <v-form @submit.prevent v-model="validForm">
      <v-text-field filled v-model.trim="signupForm.passphrase" type="text"
        lazy
        :rules="passphraseCheck"
        :placeholder="`${$t('Passphrase* - get it from Fabian')}`" id="passphrase" autocomplete="none"
      ></v-text-field>
      <div v-if="signupForm.passphrase.length > 0">
        <v-text-field filled v-model.trim="signupForm.name" type="text" placeholder="Name*" id="name" autocomplete="username"></v-text-field>
        <v-text-field filled v-model.trim="signupForm.email" type="text" autocapitalize="none" placeholder="Email*" id="email2"></v-text-field>
        <v-text-field filled v-model.trim="signupForm.password" type="password" placeholder="Password*" :hint="signupForm.password.length ? 'Seems good!' :'Min 6 characters'" id="password2" autocomplete="new-password" :rules="passwordRules">
          <v-tooltip slot="append" right v-if="signupForm.password.length >= 6" color="#303030">
            <template v-slot:activator="{ on, attrs }">
              <v-icon  v-bind="attrs" v-on="on"
                :class="signupForm.password.length >= 6  && !doubleCheckPw.length
                  ? 'grey--text'
                  : doubleCheck
                  ? signupForm.password.length && signupForm.password.length && signupForm.password === doubleCheckPw
                    ? 'green--text'
                      : 'red--text'
                        : 'green--text'"
                @click="doubleCheck = !doubleCheck"
              >
                mdi-check-all
              </v-icon>
            </template>
            <div>
              Double check password.. (optional)
            </div>
          </v-tooltip>
        </v-text-field>
        <v-text-field v-if="doubleCheck"
          :rules="doubleCheckPw.length ? passwordRepeatRules : []"
          filled
          v-model.trim="doubleCheckPw"
          type="password"
          placeholder="Repeat password*"
          :hint="doubleCheckPw.length ? 'Seems good aswell.' :'Same as above (optional)'"
          id="confirm-password-text-field"
          autocomplete="new-password"
        ></v-text-field>
        <!-- <v-checkbox v-model="consent.privacy" :label="`Checkbox 1: ${consent.privacy.toString()}`"></v-checkbox> -->

        <v-checkbox dense hide-details class="ma-0" v-model="checkbox.privacy" required :rules="[v => !!v || 'You must agree to continue!']">
          <template v-slot:label>
              I agree to the <router-link class="ml-1" to="/privacy" @click.stop>Privacy Agreement</router-link>
          </template>
        </v-checkbox>
        
        <v-checkbox dense hide-details class="ma-0" v-model="checkbox.terms" required :rules="[v => !!v || 'You must agree to continue!']">
          <template v-slot:label>
              I agree to the <router-link class="ml-1" to="/terms" @click.stop>Terms & conditions</router-link>
          </template>
        </v-checkbox>
        
        <v-checkbox dense hide-details class="ma-0" label="Get crucial updates via email" v-model="signupForm.news">
        </v-checkbox>
      </div>

      <v-card-actions class="px-0 mt-4" :class="$vuetify.breakpoint.xsOnly ? 'block' : ''">
        <v-btn  :to="'/login?email='+signupForm.email" :block="$vuetify.breakpoint.xsOnly" class="mb-4">
          <v-icon class="mr-1">mdi-arrow-u-left-top</v-icon>
          {{ $t('Back') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn type="submit" :block="$vuetify.breakpoint.xsOnly" class="mb-4" :disabled="!validForm" color="primary" @click="signup()">
          <v-icon class="mr-1">mdi-draw-pen</v-icon>
          {{ $t('Sign up') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  export default {
    name: 'SignUp',
    
    data () {
      return {
        validForm: true,
        signupForm: {
          name: '',
          passphrase: '',
          email: '',
          password: '',
          privacy: null,
          terms: null,
          news: false,
        },
        doubleCheck: false,
        doubleCheckPw: '',
        checkbox: {
          privacy: false,
          terms: false,
        },
        veryBadPasswords: [
          "123456", "password", "12345678", "qwerty", "12345", "123456789", "letmein", "1234567", "football",
          "iloveyou", "admin", "welcome", "monkey", "login", "abc123", "starwars", "123123", "dragon", "passw0rd",
          "master's degree", "hello", "freedom", "whatever", "qazwsx", "trustno1", "654321", "jordan23", "password1",
          "1234", "robert", "matthew", "jordan", "asshole", "daniel", "andrew", "lakers", "andrea", "buster",
          "joshwa", "1qaz2wsx", "12341234", "ferrari", "cheese", "computer", "corvette", "blahblah", "george",
          "mercedes", "121212", "maverick", "fuckyou", "nicole", "hunter", "sunshine", "tigger", "1989", "merlin",
          "ranger", "solo", "banana", "chelsea", "summer", "1990", "1991", "phoenix", "amanda", "cookie", "ashley",
          "bandit", "killer", "meandyou", "pepper", "jessica", "zaq1zaq1", "jennifer", "test", "hockey", "dallas",
          "password", "fuckyouasshole", "admin123", "pussy", "pass", "asdf", "william", "soccer", "london", "1q2w3e",
          "1992", "biteme", "maggie", "querty", "rangers", "charlie", "martin", "ginger", "yankees", "thunder",
          "Michelle", "aaaaaa"],
        passwordRules: [
          v => v !== '123456' || `This cannot be your password. We cannot guarantee any security that way - its the number one of most used passwords of all time.`,
          v => v.toLowerCase() !== 'password' || `This cannot be your password. We cannot guarantee any security that way - its the number two of most used passwords.`,
          v => v !== '12345' || `This cannot be your password. Haven't you seen Space Balls? We cannot guarantee any security that way - its the number five of most used passwords.`,
          v => !this.veryBadPasswords.includes(v.toLowerCase()) || `This has place ${this.veryBadPasswords.indexOf(v)+1} of the 100 most used passwords ever. Please choose something safe.`,
          v => v.length >= 6 || 'Password must be longer than 6 characters.',
          v => !/^(.)\1*$/.test(v) || 'Password cannot be only one repeated character.',
        ],
        passwordRepeatRules: [v => v === this.signupForm.password || 'Passwords do not match'],
        passphraseCheck: [v => v.length >= 1 || 'Cannot be empty.'],
      }
    },
    created() {
      this.signupForm.email = this.$route.query.email || '';
    },

    watch: {
      checkbox: {
        handler() {
          this.signupForm.privacy = this.checkbox.privacy ? this.signupForm.privacy ? this.signupForm.privacy : new Date() : null;
          this.signupForm.terms = this.checkbox.terms ? this.signupForm.terms ? this.signupForm.terms : new Date() : null;
        },
        deep: true
      }
    },

    methods: {
      signup() {
        if(!this.validForm) return
        //console.log(this.signupForm);
        this.$store.dispatch('signup', this.signupForm).then(() => {
          console.log('Successful signup.')
        }).catch(error => {
          console.log(error);
          if(error.message) {
            console.error(error.message);
            this.$toasted.global.error({msg:error.message});
          } else {
            console.error(error);
            this.$toasted.global.error({msg:error});
          }
        });
      }
    },

  }
</script>

<style scoped>
  .v-tooltip__content.menuable__content__active {
    opacity: 1 !important;
  }
</style>
